<template>
    <div class="login-out-container">
        <div class="detail-user-info">
            <img class="detail-user-ava" :src="userInfo.head_image" />
            <div class="detail-user-name">
                {{userInfo.nickname}}
                <div class="detail-user-level">VIP {{ userInfo.level }}</div>
            </div>
        </div>
        <CellGroup>
            <Cell title="用户ID" :value="userInfo.user_id" />
            <Cell title="手机号" :value="userInfo.phone_number||'保密'" />
            <Cell title="性别" :value="userInfo.sex_type == '1'?'男':userInfo.sex_type == '2'?'女':'保密'" />
            <Cell title="生日" :value="userInfo.birthday||'保密'" />
            <Cell title="最后登录时间" :value="userInfo.lastTime" />
        </CellGroup>
        <div class="login-out-btn" @click="goLoginOut">退出登录</div>
    </div>
</template>

<script>
import { Cell, CellGroup, Toast} from 'vant';
import { loginOut } from '../api/index.js'
import { timestampToDateTime } from '@/utils/auth'
export default {
    name: 'LoginOutPage',
    components: {Cell, CellGroup},
    data() {
        return {
            userInfo: {}
        }
    },
    watch: {
    },
    created() {
        this.userInfo = JSON.parse(window.localStorage.getItem('user'))
        this.userInfo.lastTime = timestampToDateTime(this.userInfo.last_login_time_unix)
    },
    methods: {
        goLoginOut(){
            loginOut({}).then(res => {
                if(res.message == 'success') {
                    Toast('退出登录成功')
                    window.localStorage.setItem('token','')
                    window.localStorage.setItem('user','')
                    window.location.href = '/userCenter'
                }
            })
        }
    }
}
</script>


<style lang="scss" scoped>
    .login-container {
        width: 100vw;
    }
    .detail-user-info {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        padding-top: 20px;
    }
    .detail-user-ava {
        width: 48px;
        height: 48px;
        border-radius: 24px;
        margin-bottom: 16px;
    }
    .detail-user-name {
        display: flex;
        font-size: 16px;
        align-items: center;
        margin-bottom: 16px;
    }
    .detail-user-level {
        background: #FFF0CB;
        color: #602D00;
        font-size: 10px;
        padding: 0 4px;
        border-radius: 2px;
        height: 14px;
        line-height: 14px;
        box-sizing: border-box;
        margin-left: 8px;
    }
    .login-out-btn {
        width: 90vw;
        height: 48px;
        border-radius: 24px;
        background: #E14A3F;
        color: #fff;
        line-height: 48px;
        text-align: center;
        margin: 48px auto;
    }
</style>