<template>
    <div class="prodItemBox" @click="goPage('/prdDetailPage', goods)">
        <img class="prodImg" :src="goods.cover_image" />
        <div class="prodName">{{ goods.goods_name }}</div>
        <div class="prodAmt">
            <b>¥</b><span>{{ goods.price / 100 }}</span><label>原价¥{{ goods.underlined_price / 100 }}</label>
        </div>
    </div>
</template>
<script>
export default {
    name: 'prodItem',
    data(){
        return {
        }
    },
    props: {
        goods: {
            type: Object,
        },
    },
    mounted() {
        // console.log('infos======', this.props.goods_name)
    },
    methods: {
        goPage(e, i){
            if(i.goods_type == 1) {
                this.$router.push({path:e,query:{goodsId: i.goods_id}})
            }else if(i.goods_type == 2) {
                window.location.href = i.out_url
            }
            // window.location.href = e + '?goodsId=' + i
        }
    },
}
</script>
<style scoped>
    .prodItemBox {
        width: 100%;
    }
    .prodImg {
        width: 100%;
        height: 100px;
        display: block;
        margin-bottom: 4px;
    }
    .prodAmt {
        display: flex;
        align-items: flex-end;
        padding-left: 4px;
    }
    .prodAmt > b {
        font-size: 10px;
        color: #EC899C;
        font-weight: 700;
    }
    .prodAmt > span {
        font-size: 14px;
        color: #EC899C;
        font-weight: 700;
        margin-right: 4px;
    }
    .prodAmt > label {
        font-size: 12px;
        color: #999;
        text-decoration: line-through;
    }
    .prodName {
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-left: 4px;
    }
</style>
