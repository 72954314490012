

import Cookies from 'js-cookie'

const TokenKey = 'vue_admin_template_token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function goWxLogin() {
  let href = window.location.href
  let baseUrl = encodeURIComponent(href)
  let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx688ce9fb229dfe0e&redirect_uri=${baseUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
  window.location.href = url
}
export function timestampToDateTime(timestamp) {
  if(!timestamp) return '--'
  const date = new Date(timestamp*1000);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10? '0'+month:month
  let day = date.getDate();
  day = day< 10? '0'+day:day
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  
  const dateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return dateTime;
}