<template>
    <div class="btn-box">
        <div class="back-btn" @click="backHome">
            <Icon name="home-o" />
        </div>
        <div class="back-btn" @click="backUp">
            <Icon name="revoke" />
        </div>
    </div>
</template>
<script>
import { Icon } from 'vant';
export default {
    name: 'backBtn',
    components: { Icon },
    methods: {
        backHome(){
            this.$router.push({path: '/homePage'})
        },
        backUp(){
            this.$router.back()
        }
    },
}
</script>
<style>
.btn-box {
    position: fixed;
    bottom: 120px;
    right: 20px;
}
.back-btn {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    box-shadow: 0px 0.06rem 0.6rem 0px rgb(153 153 153 / 50%);
    border-radius: 50%;
    background: #fff;
}
</style>
