<template>
    <div class="order-list-page">
        <div class="order-list-tabs">
            <van-tabs v-model="active">
                <van-tab title="全部"></van-tab>
                <van-tab title="待支付"></van-tab>
                <van-tab title="已支付"></van-tab>
                <van-tab title="已完成"></van-tab>
                <van-tab title="已取消"></van-tab>
                <van-tab title="退款"></van-tab>
            </van-tabs>
        </div>
        <div class="order-list" v-if="active < 5">
            <div class="order-item" v-for="(item, index) in orderList" :key="item.order_id" :index="index">
                <div class="order-title">
                    <span>壹灵雯商城</span>
                    <label class="red" v-if="item.status == 1">待支付</label>
                    <label class="red" v-else-if="item.status == 2">已支付</label>
                    <label class="red" v-else-if="item.status == 3">已完成</label>
                    <label class="red" v-else-if="item.status == 4">已取消</label>
                </div>
                <div class="order-msg" @click="goOrderDetail(item.order_id)">
                    <div class="order-pic">
                        <img :src="item.order_goods[0].cover_image" />
                    </div>
                    <div class="order-info">
                        <div class="order-name">{{item.order_goods[0].goods_name}}</div>
                        <div class="order-desc">{{item.order_goods[0].desc}}</div>
                        <!-- <div class="order-old-price"><span>¥</span>150</div> -->
                    </div>
                </div>
                <div class="refundDesc" v-if="item.order_goods[0].is_refund == 2" >订单退款申请中</div>
                <div class="refundDesc" v-if="item.order_goods[0].is_refund == 3" >订单退款审核通过</div>
                <div class="refundDesc" v-if="item.order_goods[0].is_refund == 4" >订单已退款</div>
                <div class="order-new-price" v-if="item.status == 2 || item.status == 3">实付款 <span>¥</span>{{item.order_goods[0].price / 100}}</div>
                <div class="order-operate" v-if="item.order_goods[0].is_refund == 1">
                    <div class="order-operate-item" v-if="item.status == 2" @click="goInfo(item.order_id, 1)">填写信息</div>
                    <div class="order-operate-item" v-if="item.status == 3" @click="goInfo(item.order_id, 2)">修改信息</div>
                    <div class="order-operate-item" v-if="item.status == 1" @click="goPay(item.order_id)">付款</div>
                    <div class="order-operate-item" @click="comment(item.order_goods[0].id)" v-if="item.status == 3 && item.order_goods[0].is_comment == 1">评论</div>
                    <div class="order-operate-item red" @click="refund(item.order_goods[0].id)" v-if="(item.status == 3 || item.status == 2 )&& item.order_goods[0].is_refund == 1">退款</div>
                </div>
            </div>
            <Empty description="暂无订单" v-if="!orderList.length" />
        </div>
        <div class="order-list" v-else>
            <div class="order-item" v-for="(item, index) in refundOrders" :key="index" :index="index">
                <div class="order-title">
                    <span>壹灵雯商城</span>
                    <label class="red" v-if="item.status == 1">已创建</label>
                    <label class="red" v-else-if="item.status == 2">商家同意</label>
                    <label class="red" v-else-if="item.status == 3">商家拒绝</label>
                    <label class="red" v-else-if="item.status == 4">已退款</label>
                    <label class="red" v-else-if="item.status == 5">已取消</label>
                    <label class="red" v-else-if="item.status == 6">退款中</label>
                    <label class="red" v-else-if="item.status == 7">退款异常</label>
                </div>
                <div class="order-msg" @click="goRefundDetail(item.refund_order_id)">
                    <div class="order-pic">
                        <img :src="item.order_goods.cover_image" />
                    </div>
                    <div class="order-info">
                        <div class="order-name">{{item.order_goods.goods_name}}</div>
                        <div class="order-desc">退款原因：{{item.reason}}</div>
                        <!-- <div class="order-old-price"><span>¥</span>150</div> -->
                    </div>
                </div>
                <div class="order-new-price">实付款 <span>¥</span>{{item.order_goods.price / 100}}</div>
                <div class="order-operate">
                    <div class="order-operate-item" v-if="item.status == 1" @click="cancelRefund(item.id)">取消退款</div>
                </div>
            </div>
            <Empty description="暂无退款单" v-if="!refundOrders.length" />
        </div>
        <van-dialog v-model="show" title="填写信息" :beforeClose="beforeClose" show-cancel-button>
            <div class="check-info">
                <van-form>
                    <van-field
                        v-model="name"
                        name="姓名"
                        label="姓名"
                        placeholder="姓名"
                        :rules="[{ required: true, message: '请填姓名' }]"
                    />
                    <van-field
                        v-model="wechat_id"
                        name="微信号"
                        label="微信号"
                        placeholder="微信号"
                        :rules="[{ required: true, message: '请填写微信号' }]"
                    />
                    <van-field
                        v-model="phone_number"
                        name="手机号"
                        label="手机号"
                        placeholder="手机号"
                        :rules="[{ required: true, message: '请填写手机号' }]"
                    />
                    <van-field
                        v-model="email"
                        name="邮箱"
                        label="邮箱"
                        placeholder="邮箱"
                        :rules="[{ required: true, message: '请填写邮箱' }]"
                    />
                </van-form>
            </div>
        </van-dialog>
        <van-dialog v-model="show1" title="退款原因" :beforeClose="beforeClose1" show-cancel-button>
            <div class="check-info">
                <van-form>
                    <van-field
                        v-model="reason"
                        rows="2"
                        text="退款原因"
                        type="textarea"
                        placeholder="请输入退款原因"
                    />
                </van-form>
            </div>
        </van-dialog>
        <van-dialog v-model="show2" title="评论" :beforeClose="beforeClose2" show-cancel-button>
            <div class="check-info">
                <van-form>
                    <van-field
                        v-model="commentText"
                        rows="3"
                        text="评论内容"
                        type="textarea"
                        placeholder="请输入评论内容"
                    />
                </van-form>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import { Dialog, Empty } from 'vant';
import { orders, createPay, paySignInfo, wxSignInfo, refundList, creaRefund, cancelRefund, expandOrder, expandDetail, createComment } from '../api/index.js'
import wx from 'weixin-js-sdk'
export default {
    name:'orderList',
    components: { Empty },
    data() {
        return {
            active: 0,
            show: false,
            name: '',
            wechat_id:'',
            phone_number:'',
            email: '',
            orderList: [],
            refundOrders: [],
            pageNNum: 1,
            show1: false,
            refundOrderId: '',
            reason: '',
            infoId: '',
            commentText: '',
            show2: false,
            commentOrderId: ''
        }
    },
    watch: {
        active(newV, oldV){
            console.log(newV, oldV)
            // this.$router.replace({path:'/orderList',query:{orderStatus:newV}})
            this.getOrders(newV)
        }
    },
    activated() {
        this.active = parseInt(this.$route.query.orderStatus)
        this.getOrders(this.active);
    },
    methods: {
        getOrders(e){
            let upData = {
                page: this.pageNNum,
                page_size: 10,
                status: e
            }
            if(e < 5) {
                orders(upData).then(res => {
                    if(res.message == 'success') {
                        this.orderList = res.data.data || []
                    }
                })
            }else {
                upData.status = 0
                refundList(upData).then(res => {
                    console.log('refundList=======>', res)
                    this.refundOrders = res.data.data || []
                })
            }
        },
        refund(e){
            this.show1 = true
            this.refundOrderId =  e
        },
        goInfo(e, i){
            this.infoId = e
            this.show = true
            if(i == 2) {
                expandDetail({order_id: e}).then(res => {
                    if(res.message == 'success') {
                        this.name = res.data.info_config.name,
                        this.wechat_id = res.data.info_config.wechat_id,
                        this.phone_number = res.data.info_config.phone_number,
                        this.email = res.data.info_config.email
                    }
                })
            }else {
                this.name = '',
                this.wechat_id = '',
                this.phone_number = '',
                this.email = ''
            }
        },
        goOrderDetail(e){
            this.$router.push({path:'/orderDetail',query:{id: e }})
        },
        goRefundDetail(e){
            this.$router.push({path:'/refundDetail',query:{id: e}})
        },
        comment(e){
            this.show2 = true
            this.commentOrderId =  e
        },
        cancelRefund(e){
            Dialog.confirm({
                title: '取消退款',
                message: '是否确定要取消退款？',
            })
            .then(() => {
                cancelRefund({id: e}).then(res => {
                    if( res.message == 'success' ) {
                        this.getOrders(5)
                    }
                })
            })
            .catch(() => {
                // on cancel
            });
        },
        goPay(e){
            createPay({order_id: e}).then(res => {
                console.log('createPay=======>', res)
                if(res.message == 'success') {
                    wxSignInfo({url: 'https://shop.lscyyw.top/orderList'}).then(item => {
                        if(item.message == 'success') {
                            paySignInfo({prepay_id: res.data}).then(data => {
                                console.log('paySignInfo======>', data)
                                if(data.message == 'success') {
                                    this.weixinPay(data.data, item.data, e)
                                }
                            })
                        }
                    })
                }
            })
        },
        weixinPay(e, i, x){
            wx.config({
                debug: false, // 开启debug模式，输出日志信息
                appId: i.app_id, // 必填，公众号的唯一标识
                timestamp: i.timestamp, // 必填，生成签名的时间戳
                nonceStr: i.nonce_str, // 必填，生成签名的随机串
                signature: i.sign, // 必填，签名
                jsApiList: ['chooseWXPay'], // 必填，需要使用的JS接口列表
            });
            wx.ready(() => {
                console.log('微信JS-SDK初始化成功');
                wx.chooseWXPay({
                    appId: e.app_id, 
                    timestamp: e.timestamp,
                    nonceStr: e.nonce_str,
                    package: e.package,
                    signType: e.sign_type,
                    paySign: e.sign,
                    success: (res) => {
                    console.log('微信支付成功', res);
                        this.$router.push({path:'/successPage',query:{backUrl: '/orderList?orderStatus=2', order_id: x}})
                    },
                    fail: (err) => {
                    console.error('微信支付失败', err);
                    // TODO: 处理支付失败的逻辑
                    }
                });
            });

        },
        beforeClose(action, done){
            if (action === 'confirm') {
                if(!this.wechat_id) {
                    Dialog.alert({
                        title: '错误',
                        message: '请输入微信号',
                    }).then(() => {
                    });
                    done(false);
                }else {
                    let expandData = {
                        order_id: this.infoId,
                        info_config: {
                            name: this.name,
                            wechat_id: this.wechat_id,
                            phone_number: this.phone_number,
                            email: this.email
                        }
                    }
                    expandOrder(expandData).then(res => {
                        if(res.message == 'success') {
                            Dialog.alert({
                                title: '填写成功',
                                message: '请耐心等待客服人员联系',
                            })
                            .then(() => {
                                this.getOrders(3)
                                done();
                            })
                        }
                    })
                }
            } else {
                done();
            }
        },
        beforeClose1(action, done){
            if (action === 'confirm') {
                if(!this.reason) {
                    Dialog.alert({
                        title: '错误',
                        message: '请输入退款原因',
                    }).then(() => {
                    });
                    done(false);
                }else {
                    creaRefund({order_goods_id: this.refundOrderId, reason:this.reason}).then(res => {
                        if( res.message == 'success' ) {
                            Dialog.alert({
                                title: '申请成功',
                                message: '等待审核人员人工退款',
                            })
                            .then(() => {
                                this.getOrders(0)
                                done();
                                this.reason = ''
                            })
                        }
                    })
                }
            } else {
                this.reason = ''
                done();
            }
        },
        beforeClose2(action, done){
            if (action === 'confirm') {
                if(!this.commentText) {
                    Dialog.alert({
                        title: '错误',
                        message: '请输入评论内容',
                    }).then(() => {
                    });
                    done(false);
                }else {
                    createComment({order_goods_id: this.commentOrderId,content: this.commentText}).then(res => {
                        if( res.message == 'success' ) {
                            Dialog.alert({
                                title: '评论成功',
                                message: '感谢您给出的评论！',
                            })
                            .then(() => {
                                this.getOrders(0)
                                done();
                                this.commentText = ''
                            })
                        }
                    })
                }
            } else {
                this.reason = ''
                done();
            }
        }
    },
}
</script>
<style>
.order-list-page {
    width: 100vw;
}
.order-list-tabs {
    width: 100%;
    height: 44px;
    overflow-x: auto;
    overflow-y: hidden;
}
.order-list {
    height: calc( 100vh - 44px );
    background: #f9f9f9;
    overflow: auto;
}
.order-item {
    padding: 12px;
    margin-bottom: 12px;
    background: #fff;
    border-radius: 8px;
    position: relative;
}
.order-title {
    font-size: 18px;
    color: #000;
    margin-bottom: 12px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
}
.order-title > label {
    font-size: 12px;
}
.order-msg {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.order-pic {
    width: 48px;
    height: 48px;
}
.order-pic > img {
    width: 48px;
    height: 48px;
}
.order-info {
    width: calc( 100% - 60px );
    position: relative;
}
.order-name {
    width: calc( 100% - 40px );
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #000;
    font-weight: 700;
    margin-bottom: 8px;
}
.order-desc {
    width: 100%;
    height: 42px;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    -webkit-line-clamp: 2;
    color: #999;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.order-old-price {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 12px;
    font-weight: 700;
}
.order-old-price > span {
    font-size: 8px;
}
.order-new-price {
    font-size: 14px;
    font-weight: 700;
    color: #000;
    text-align: right;
    margin-bottom: 8px;
}
.order-new-price > span {
    font-size: 10px;
}
.order-operate {
    display: flex;
    justify-content: flex-end;
}
.order-operate-item {
    padding: 4px 10px;
    border: 1px solid #999;
    font-size: 14px;
    border-radius: 16px;
    margin-left: 8px;
    color: #999;
}
.red {
    color: #E14A3F;
    border-color: #E14A3F;
}
.refundDesc {
    font-size: 14px;
    color: #E14A3F;
}
</style>

