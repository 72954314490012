<template>
    <div class="order-detail-page">
        <div class="order-status">
            <van-icon class="order-status-icon" color="#FCA33E" name="diamond-o" />
            <div class="order-status-info">
                <div>{{params.status == 1?'已创建':params.status == 2?'已支付':params.status == 3?'已完成':'已取消'}}</div>
                <label v-if="params.status == 1">订单已经创建，请及时付款以免订单过期。</label>
                <label v-else-if="params.status == 2">订单已经支付，请及时填写用户信息方便商家联系。</label>
                <label v-else-if="params.status == 3">订单已经完成，若商家未联系客户，请及时联系商家核对，请及时联系商家核对，请及时联系商家核对</label>
                <label v-else>订单已经取消，如果要购买其他商品，请重新下单。</label>
            </div>
        </div>
        <div class="order-goods">
            <div class="order-msg" @click="goGoodsDetail">
                <div class="order-pic">
                    <img :src="params.order_goods[0].cover_image" />
                </div>
                <div class="order-info">
                    <div class="order-name">{{params.order_goods[0].goods_name}}</div>
                    <div class="order-desc">{{params.order_goods[0].desc}}</div>
                    <div class="order-old-price"><span>¥</span>{{params.amount/100}}</div>
                </div>
            </div>
            <div class="refundDesc" v-if="params.order_goods[0].is_refund == 2" >订单退款申请中</div>
            <div class="refundDesc" v-if="params.order_goods[0].is_refund == 3" >订单退款审核通过</div>
            <div class="refundDesc" v-if="params.order_goods[0].is_refund == 4" >订单已退款</div>
            <div class="order-operate">
                <div class="order-operate-item red" v-if="(params.status == 2 || params.status == 3) && params.order_goods[0].is_refund == 1" @click="refund">退款</div>
                <div class="order-operate-item" v-if="params.status ==3 && params.order_goods[0].is_comment == 1" @click="comment(params.order_goods[0].id)">评论</div>
            </div>
            <div class="order-amt" v-if="params.status == 2 || params.status == 3">
                <span>实付款</span>
                <div><span>¥</span>{{params.payment_amount/100}}</div>
            </div>
            <div class="cut-line"></div>
            <div class="order-other-info">
                <div class="order-other-info-item">
                    <div>订单编号:</div>
                    <div>{{params.order_id}}</div>
                </div>
                <div class="order-other-info-item">
                    <div>用户ID:</div>
                    <div>{{params.user_id}}</div>
                </div>
                <div class="order-other-info-item">
                    <div>订单创建时间:</div>
                    <div>{{params.create_time}}</div>
                </div>
                <div class="order-other-info-item" v-if="params.status == 2">
                    <div>支付时间:</div>
                    <div>{{params.pay_time}}</div>
                </div>
            </div>
        </div>
        <van-dialog v-model="show1" title="退款原因" :beforeClose="beforeClose1" show-cancel-button>
            <div class="check-info">
                <van-form>
                    <van-field
                        v-model="reason"
                        rows="2"
                        text="退款原因"
                        type="textarea"
                        placeholder="请输入退款原因"
                    />
                </van-form>
            </div>
        </van-dialog>
        <van-dialog v-model="show2" title="评论" :beforeClose="beforeClose2" show-cancel-button>
            <div class="check-info">
                <van-form>
                    <van-field
                        v-model="commentText"
                        rows="3"
                        text="评论内容"
                        type="textarea"
                        placeholder="请输入评论内容"
                    />
                </van-form>
            </div>
        </van-dialog>
    </div>
</template>
<script>
// import wx from 'weixin-js-sdk'
import { ordersDetail, creaRefund, createComment } from '../api/index.js'
import { Dialog } from 'vant';
import { timestampToDateTime } from '@/utils/auth'
export default {
    name: 'orderDetail',
    data() {
        return {
            params: {
                order_goods: [{}]
            },
            show1: false,
            show2: false,
            reason: '',
            commentOrderId: '',
            commentText: '',
        }
    },
    activated() {
        this.getOrderDetail(this.$route.query.id)
    },
    methods: {
        comment(e){
            this.show2 = true
            this.commentOrderId =  e
        },
        refund(){
            this.show1 = true
        },
        getOrderDetail(e){
            ordersDetail({order_id: e}).then(res => {
                console.log('getOrderDetail========>', res)
                if(res.message == 'success') {
                    this.params = res.data
                    this.params.create_time = timestampToDateTime(this.params.create_time_unix)
                    this.params.pay_time = timestampToDateTime(this.params.pay_time_unix)
                }
            })
        },
        goGoodsDetail(){
            if(this.params.order_goods[0].goods_type == 2) {
                window.location.href = this.params.order_goods[0].out_url
            } else {
                this.$router.push({path:'/prdDetailPage',query:{goodsId: this.params.order_goods[0].goods_id}})
            }
        },
        beforeClose1(action, done){
            if (action === 'confirm') {
                if(!this.reason) {
                    Dialog.alert({
                        title: '错误',
                        message: '请输入退款原因',
                    }).then(() => {
                    });
                    done(false);
                }else {
                    creaRefund({order_goods_id: this.params.order_goods[0].id, reason:this.reason}).then(res => {
                        if( res.message == 'success' ) {
                            Dialog.alert({
                                title: '申请成功',
                                message: '等待审核人员人工退款',
                            })
                            .then(() => {
                                this.$router.back()
                                done();
                                this.reason = ''
                            })
                        }
                    })
                }
            } else {
                this.reason = ''
                done();
            }
        },
        beforeClose2(action, done){
            if (action === 'confirm') {
                if(!this.commentText) {
                    Dialog.alert({
                        title: '错误',
                        message: '请输入评论内容',
                    }).then(() => {
                    });
                    done(false);
                }else {
                    createComment({order_goods_id: this.commentOrderId,content: this.commentText}).then(res => {
                        if( res.message == 'success' ) {
                            Dialog.alert({
                                title: '评论成功',
                                message: '感谢您给出的评论！',
                            })
                            .then(() => {
                                this.getOrderDetail(this.$route.query.id)
                                done();
                                this.commentText = ''
                            })
                        }
                    })
                }
            } else {
                this.reason = ''
                done();
            }
        }
    },
}
</script>
<style>
.order-detail-page {
    width: 100vw;
    background: #f9f9f9;
    padding: 12px 8px;
    box-sizing: border-box;
}
.order-status {
    background: #fff;
    padding: 12px;
    display: flex;
    font-size: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    margin-bottom: 12px;
}
.order-status-info {
    width: 100px;
    flex-grow: 1;
    margin-left: 8px;
}
.order-status-info > div {
    color: #FCA33E;
    font-size:16px;
    font-weight: 700;
}
.order-status-info > label {
    color: #999;
    font-size: 12px;
    line-height: 16px;
    display: block;
}
.order-status-icon {
    margin-top: 2px;
}
.order-goods {
    background: #fff;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 8px;
}
.order-operate {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
}
.order-amt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}
.order-amt > span {
    font-size: 14px;
    color: #999;
}
.order-amt > div {
    font-size: 14px;
    color: #E14A3F;
    font-weight: 700;
}
.order-amt > div > span {
    font-size: 12px;
}
.cut-line {
    height: 1px;
    width: 100%;
    background: #ccc;
    margin-bottom: 12px;
}
.order-other-info-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}
.order-other-info-item > div {
    font-size: 14px;
    color: #999;
}
.refundDesc {
    font-size: 14px;
    color: #E14A3F;
}
</style>
