<template>
    <div class="home-warp">
        <div class="home-search-bar" @click="goSearch">
            <img class="search-icon" src="../assets/img/search.png" />
            <div class="home-search-text">请输入商品名称</div>
        </div>
        <div class="home-hot-cate">
            <div class="home-hot-cate-item greenBg" v-for="item in topCateList" @click="goPrdList(item.id)" :key="item.id">{{item.name}}</div>
        </div>
        <div class="home-all-cate-list">
            <div class="home-all-cate-list-item" v-for="item in cateList" @click="goPrdList(item.id)" :key="item.id">
                <img :src="item.image" />
                <span>{{item.name}}</span>
            </div>
        </div>
        <div class="home-hot-teacher-list" v-for="(cate, index) in proCateList" :key="'cate' + index" v-show="cate.children&&cate.children.length">
            <h5 @click="goPrdList(cate.id)">{{ cate.name }}</h5>
            <div class="home-hot-teacher-box">
                <div class="home-hot-teacher-item" v-for="item in cate.children" :key="item.goods_id+'i'" @click="goPage('/prdDetailPage', item)" >
                    <img :src="item.cover_image" />
                    <div>
                        <div class="teacher-name">
                            <span>{{item.goods_name}}</span>
                            <label></label>
                        </div>
                        <div class="teacher-info">
                            <span>阿雯精选</span>
                        </div>
                    </div>
                    <!-- <p>张三老师简介………………</p> -->
                </div>
            </div>
        </div>
        <div class="homePageProdList">
            <div class="prodItemWrap" v-for="item in goodsList" :key="item.goods_id">
                <prodItem :goods="item" />
            </div>
        </div>
    </div>
</template>
<script>
import prodItem from '../components/prodItem.vue'
import { types, goods } from '../api/index.js'
export default {
    name:'homePage',
    components: {
        prodItem
    },
    data(){
        return {
            topCateList: [],
            cateList: [],
            proCateList: [],
            goodsList: [],
            teacherList: []
        }
    },
    created() {
        this.getTopCate()
        this.getCate();
        this.getProCate();
        this.getOthers();
    },
    methods: {
        getTopCate(){
            types({type: 3}).then(res => {
                if(res.message == 'success') {
                    this.topCateList = res.data
                }
            })
        },
        getCate(){
            types({type: 1}).then(res => {
                if(res.message == 'success') {
                    this.cateList = res.data
                }
            })
        },
        getProCate(){
            types({type: 2}).then(res => {
                if(res.message == 'success') {
                    this.proCateList = res.data
                    for(let i = 0;i < this.proCateList.length;i++) {
                        this.getTeacher(this.proCateList[i].id, i)
                    }
                }
            })
        },
        goSearch(){
            this.$router.push({path:'/prdListPage',query:{cateId: 0}})
        },
        goPrdList(e){
            this.$router.push({path:'/prdListPage',query:{cateId: e}})
        },
        getTeacher(e, i){
            console.log('getTeacher======>',e,i)
            let goodsData = {
                page: 1,
                page_size: 10,
                home_show_type: 0,
                sale_type: 3,
                type_id: e
            }
            goods(goodsData).then(res => {
                if(res.message == 'success') {
                    this.teacherList = res.data.data
                    this.proCateList[i].children = res.data.data
                    console.log('goodsList======>',this.proCateList)
                    this.$forceUpdate();
                }
            })
        },
        getOthers(){
            let goodsData = {
                page: 1,
                page_size: 10,
                home_show_type: 2,
                sale_type: 0
            }
            goods(goodsData).then(res => {
                if(res.message == 'success') {
                    this.goodsList = res.data.data
                    console.log('goodsList======>',this.goodsList)
                    this.$forceUpdate()
                }
            })
        },
        goPage(e, i){
            if(i.goods_type == 1) {
                this.$router.push({path:e,query:{goodsId: i.goods_id}})
            }else if(i.goods_type == 2) {
                window.location.href = i.out_url
            }
        }
    },
}
</script>
<style scoped>
    .home-warp {
        width: 100%;
        min-height: 100vh;
        box-sizing: border-box;
        padding-top: 16px;
        background: url('../assets/img/blankBg.jpeg');
        background-size: contain;
    }
    .home-search-bar {
        width: 90vw;
        height: 32px;
        border: 1px solid #ccc;
        border-radius: 16px;
        margin: 0 auto 16px;
        display: flex;
        align-items: center;
    }
    .search-icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        margin-left: 16px;
    }
    .home-search-text {
        font-size: 16px;
        color: #ccc;
    }
    .home-hot-cate {
        width: 90vw;
        margin: 0 auto 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .home-hot-cate-item {
        width: 10%;
        height: 60px;
        flex: 1;
        margin: 0 8px;
        line-height: 60px;
        text-align: center;
        font-size: 20px;
        color: #fff;
        border-radius: 8px;
    }
    .home-hot-cate-item:nth-child(1) {
        margin-left: 0;
    }
    .home-hot-cate-item:nth-last-child(1) {
        margin-right: 0;
    }
    .greenBg {
        background: #7ECDCF;
    }
    .redBg {
        background: #EC899C;
    }
    .blueBg {
        background: #69C0FF;
    }
    .home-all-cate-list {
        margin: 0 auto 24px;
        width: 90vw;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .home-all-cate-list-item {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
    }
    .home-all-cate-list-item > img {
        width: 36px;
        height: 36px;
        margin-bottom: 8px;
    }
    .home-all-cate-list-item > span {
        font-size: 12px;
        color: #fff;
    }
    .home-hot-teacher-list {
        background: rgba(255,255,255,0.90);
        border-radius: 8px;
        width: 90vw;
        margin: 0 auto 24px;
        padding: 8px;
        box-sizing: border-box;
    }
    .home-hot-teacher-list > h5 {
        padding: 0;
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 16px;
    }
    .home-hot-teacher-box {
        width: 100%;
    }
    .home-hot-teacher-item {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        padding: 8px 16px 0;
        box-sizing: border-box;
        margin-bottom: 8px;
    }
    .home-hot-teacher-item > img {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        margin-right: 8px;
    }
    .home-hot-teacher-item > div {
        width: calc(100% - 56px);
    }
    .teacher-name {
        width: 100%;
    }
    .teacher-name > span {
        font-size: 16px;
        font-weight: 700;
        margin-right: 8px;
    }
    .teacher-name > label {
        font-size: 12px;
        font-weight: 500;
        color: #999;
    }
    .teacher-info {
        width: 100%;
    }
    .teacher-info > span {
        font-size: 12px;
        display: inline-block;
        padding: 1px 4px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-right: 6px;
    }
    .home-hot-teacher-item > p {
        width: 100%;
        padding: 0;
        margin: 8px 0 0;
        font-size: 14px;
        padding-bottom: 8px;
    }
    .homePageProdList {
        padding: 0 24px 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .prodItemWrap {
        width: calc(50% - 8px);
        margin-bottom: 16px;
        background: rgba(255,255,255,0.90);
        border-radius: 8px;
        overflow: hidden;
    }
</style>

