<template>
    <div class="nav-wrap">
        <div>
            <router-view></router-view>
        </div>
        <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o" @click="goPage('/homePage')">主页</van-tabbar-item>
            <van-tabbar-item icon="search" @click="goPage('/prdListPage')">商品列表</van-tabbar-item>
            <van-tabbar-item icon="friends-o" @click="goPage('/userCenter')">个人中心</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
export default {
    name: 'tabBar',
    data(){
        return {
            active: 0
        }
    },
    created() {
        console.log(window.location)
        let localHash = window.location.href
        if(localHash.indexOf('homePage')> -1) {
            this.active = 0
        }else if(localHash.indexOf('prdListPage')> -1) {
            this.active = 1
        }else if(localHash.indexOf('userCenter')> -1) {
            this.active = 2
        }
    },
    methods: {
        goPage(e){
            this.$router.push({path:e,replace: true, query:{}})
        }
    },
    watch:{
        $route(newV){
            let localHash = newV.fullPath
            if(localHash.indexOf('homePage')> -1) {
                this.active = 0
            }else if(localHash.indexOf('prdListPage')> -1) {
                this.active = 1
            }else if(localHash.indexOf('userCenter')> -1) {
                this.active = 2
            }
        }
    }
}
</script>
<style scoped>
.nav-wrap {
    min-height: 100vh;
}
</style>
