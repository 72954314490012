<template>
    <div class="user-center">
        <div v-if="user.user_id" class="user-msg" @click="goLoginOut">
            <div class="user-ava">
                <img :src="user.head_image" />
            </div>
            <div class="user-info">
                <div class="user-name">{{ user.nickname }}</div>
                <div class="user-id">用户 ID: {{ user.user_id }}</div>
                <div class="user-other-info">
                    <div class="user-level">VIP {{ user.level }}</div>
                </div>
            </div>
        </div>
        <div v-else class="user-msg-login" @click="goLogin">
            <div class="user-ava">
                <img src="../assets/img/defaultAva.png" />
            </div>
            <div class="user-info-login">
                <div class="user-no">点击授权登陆</div>
            </div>
        </div>
        <div class="usercenter-order-list-wrap">
            <div class="usercenter-order-list">
                <div class="usercenter-order-list-title">
                    <div>我的订单</div>
                    <label @click="goOrderListPage(0)">全部<van-icon name="arrow" /></label>
                </div>
                <div class="usercenter-order-list-box">
                    <div class="usercenter-order-list-item" @click="goOrderListPage(1)">
                        <van-icon name="coupon-o" />
                        <span>待支付</span>
                    </div>
                    <div class="usercenter-order-list-item" @click="goOrderListPage(2)">
                        <van-icon name="point-gift-o" />
                        <span>已支付</span>
                    </div>
                    <div class="usercenter-order-list-item" @click="goOrderListPage(3)">
                        <van-icon name="gift-o" />
                        <span>已完成</span>
                    </div>
                    <div class="usercenter-order-list-item" @click="goOrderListPage(5)">
                        <van-icon name="revoke" />
                        <span>已取消</span>
                    </div>
                    <div class="usercenter-order-list-item" @click="goOrderListPage(5)">
                        <van-icon name="after-sale" />
                        <span>退款</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { userInfo } from '../api/index.js'
export default {
    name:'userCenter',
    data(){
        return {
            code: '',
            user: {}
        }
    },
    mounted() {
        if(window.localStorage.getItem('user')) {
            this.user = JSON.parse(window.localStorage.getItem('user'))
        }else {
            if(window.localStorage.getItem('token')) {
                this.getUser()
            }
        }
    },
    methods: {
        goOrderListPage(e){
            this.$router.push({path:'/orderList',query:{orderStatus: e}})
        },
        goLogin(){
            let redirectUrl = window.location.href
            window.location.href = '/login?redirectUrl=' + encodeURIComponent(redirectUrl)
        },
        getUser(){
            userInfo({}).then(res => {
                if( res.message == 'success' ) {
                    window.localStorage.setItem('user', JSON.stringify(res.data))
                    this.user = res.data
                }
            })
        },
        goLoginOut(){
            this.$router.push({path: '/loginOut'})
        }
    },
}
</script>
<style>
.user-center {
    width: 100vw;
    background: #f9f9f9;
    padding-bottom: 36px;
    box-sizing: border-box;
}
.user-msg {
    width: 100%;
    background: #fff;
    display: flex;
    padding: 12px;
    box-shadow: 0px 0.04rem 0.4rem 0px rgb(153 153 153 / 10%);
    margin-bottom: 4px;
    box-sizing: border-box;
}
.user-msg-login {
    width: 100%;
    background: #fff;
    display: flex;
    padding: 12px;
    box-shadow: 0px 0.04rem 0.4rem 0px rgb(153 153 153 / 10%);
    margin-bottom: 4px;
    display: flex;
    align-items: center;
}
.user-ava {
    width: 48px;
    height: 48px;
    margin-right: 8px;
}
.user-ava > img {
    width: 48px;
    height: 48px;
}
.user-name {
    font-size: 16px;
    color: #000;
    margin-bottom: 4px;
}
.user-id {
    font-size: 12px;
    color: #999;
    margin-bottom: 4px;
}
.user-other-info {
    display: flex;
}
.user-level {
    background: #FFF0CB;
    color: #602D00;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 4px;
}
.usercenter-order-list-wrap {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
}
.usercenter-order-list {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    padding: 8px 16px;
    box-sizing: border-box;
    box-shadow: 0px 0.04rem 0.4rem 0px rgb(153 153 153 / 10%);
}
.usercenter-order-list-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}
.usercenter-order-list-title > div {
    font-size: 16px;
    color: #000;
}
.usercenter-order-list-title > label {
    font-size: 14px;
    color: #999;
}
.usercenter-order-list-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.usercenter-order-list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin: 0 12px;
}
.usercenter-order-list-item > span {
    font-size: 14px;
    margin-top: 4px;
}
</style>
