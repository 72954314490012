<template>
    <div class="comment-list-page">
        <div class="comment-list-item" v-for="item in commentsData" :key="item.order_id">
            <commentItem :item="item" />
        </div>
        <Empty description="暂无评论" v-if="!commentsData.length" />
    </div>
</template>
<script>
import commentItem from '../components/commentItem.vue'
import { comments } from '../api/index.js'
import { Empty } from 'vant';
export default {
    name: 'commentList',
    components: {
        commentItem,
        Empty
    },
    data(){
        return {
            pageNum: 1,
            commentsData: []
        }
    },
    created() {
        this.getComments(this.$route.query.goodsId)
    },
    methods: {
        getComments(e){
            let upData = {
                goods_id: e,
                page_size: 10,
                page: this.pageNum
            }
            comments(upData).then(res => {
                if(res.message == 'success') {
                    this.commentsData = res.data.data || []
                }
            })
        }
    },
}
</script>
<style>
.comment-list-page {
    background: #f9f9f9;
    padding: 12px;
    width: 100vw;
    box-sizing: border-box;
}
.comment-list-item {
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 12px;
}
</style>
