<template>
    <div class="success-page">
        <div class="success-page-inner">
            <img src="../assets/img/successIcon.jpeg" />
            <div class="success-text">支付成功</div>
            <div class="success-desc">请前往订单列表填写扩展信息完成订单</div>
            <div class="success-btn-box">
                <van-button round type="primary" @click="goBackUrl">前往订单列表查看</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import { checkPayStatus } from '../api/index.js'
export default {
    name:'successPage',
    created() {
        if(this.$route.query.order_id) {
            checkPayStatus({order_id: this.$route.query.order_id})
        }
    },
    methods: {
        goBackUrl(){
            this.$router.push({path: this.$route.query.backUrl})
        }
    },
}
</script>
<style lang="scss">
    .success-page-inner {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 80px;
        box-sizing: border-box;
    }
    .success-page-inner > img {
        width: 72px;
        height: 72px;
        margin-bottom: 24px;
    }
    .success-text {
        font-size: 20px;
        text-align: center;
        margin-bottom: 24px;
    }
    .success-desc {
        font-size: 18px;
        text-align: center;
        color: #E14A3F;
        font-weight: 700;
        margin-bottom: 24px;
    }
    .success-btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>



