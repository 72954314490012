<template>
  <div class="login-container">
    <div class="login-loading">
      <Loading color="#fff" vertical text-color="#fff">跳转登录中...</Loading>
    </div>
  </div>
</template>

<script>
import { Loading } from 'vant';
import { login } from '../api/index.js'
import { goWxLogin } from '@/utils/auth'
export default {
    name: 'LoginPage',
    components: {Loading},
    data() {
        return {
        }
    },
    watch: {
    },
    created() {
        let code = this.$route.query.code
        console.log('loginCode=======>', code)
        if(!code){
            goWxLogin()
        }else if( code ) {
            this.getLoginInfo(code)
        }
    },
    methods: {
        getLoginInfo(e){
            login({code: e, login_type: 1}).then(res => {
                if(res.message == 'success') {
                    window.localStorage.setItem('token',res.data)
                    window.location.href = this.$route.query.redirectUrl
                }else {
                    goWxLogin()
                }
            })
      }
    }
}
</script>


<style lang="scss" scoped>
  .login-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-loading {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
    border-radius: 8px;
  }
</style>