<template>
    <div class="prod-detail-page">
        <img class="prodPic" :src="goodItem.cover_image" alt="">
        <div class="prod-name">{{ goodItem.goods_name }}</div>
        <div class="prod-price-info">
            <div class="prodAmt">
                <b>¥</b><span>{{ goodItem.price / 100 }}</span><label>原价¥{{ goodItem.underlined_price / 100 }}</label>
            </div>
            <div class="prod-left-count">销售量：{{goodItem.goods_expands.sale_count}}件  浏览量：{{goodItem.goods_expands.view_count}}次</div>
        </div>
        <div class="prod-evaluate">
            <van-cell title="评论" is-link :to="'/commentList?goodsId=' + this.$route.query.goodsId" value="更多" />
            <div v-for="item in commentsData" :key="item.order_id">
                <commentItem :item="item" />
            </div>
        </div>
        <div class="prod-more-info">
            <div class="prod-more-info-title">
                <div>- - - - 商品详情 - - - -</div>
            </div>
            <div class="prod-content-item" v-for="(item, index) in contentData" :key="'contentKey' + index">
                <img class="prodPic" :src="item.images" alt="">
                <p v-if="item.content" class="info-chapter">{{item.content}}</p>
            </div>
        </div>
        <div class="prod-bottom-btn" @click="toBuy">立&nbsp;&nbsp;&nbsp;即&nbsp;&nbsp;&nbsp;购&nbsp;&nbsp;&nbsp;买</div>
    </div>
</template>
<script>
import commentItem from '../components/commentItem.vue'
import { goodsDetail, comments, createOrders, createPay, paySignInfo, wxSignInfo } from '../api/index.js'
import wx from 'weixin-js-sdk'
import { Dialog } from 'vant';
export default {
    name: 'prdDetailPage',
    components: {
        commentItem
    },
    data(){
        return {
            goodItem: {},
            commentsData: [],
            contentData: []
        }
    },
    activated() {
        console.log(this.$route.query)
        this.getDetail(this.$route.query.goodsId)
        this.getComments(this.$route.query.goodsId)
    },
    methods: {
        getDetail(e){
            goodsDetail({goods_id: e}).then(res => {
                console.log(res)
                if(res.message == 'success') {
                    this.goodItem = res.data
                    let content = res.data.goods_details ? res.data.goods_details.content : ''
                    if( content ) {
                        this.contentData = JSON.parse(content)
                        console.log('this.contentData======>', this.contentData)
                    }
                }
            })
        },
        getComments(e){
            comments({goods_id: e, page: 1, page_size:1}).then(res => {
                console.log(res)
                if(res.message == 'success') {
                    this.commentsData = res.data.data || []
                }
            })
        },
        toBuy(){
            Dialog.confirm({
                title: '确定购买',
                message: '是否确定要购买' + this.goodItem.goods_name + '？',
            })
            .then(() => {
                let orderData= [{
                    goods_id: this.goodItem.goods_id,
                    number: 1
                }]
                createOrders({order_goods:orderData}).then(res => {
                    if(res.message == 'success') {
                        this.toPay(res.data.order_id)
                    }
                })
            })
            .catch(() => {
                // on cancel
            });
        },
        toPay(e){
            createPay({order_id: e}).then(res => {
                if(res.message == 'success') {
                    wxSignInfo({url: 'https://shop.lscyyw.top/prdDetailPage'}).then(item => {
                        if(item.message == 'success') {
                            paySignInfo({prepay_id: res.data}).then(data => {
                                if(data.message == 'success') {
                                    this.weixinPay(data.data, item.data, e)
                                }
                            })
                        }
                    })
                }
            })
        },
        weixinPay(e, i, x){
            wx.config({
                debug: false, // 开启debug模式，输出日志信息
                appId: i.app_id, // 必填，公众号的唯一标识
                timestamp: i.timestamp, // 必填，生成签名的时间戳
                nonceStr: i.nonce_str, // 必填，生成签名的随机串
                signature: i.sign, // 必填，签名
                jsApiList: ['chooseWXPay'], // 必填，需要使用的JS接口列表
            });
            wx.ready(() => {
                console.log('微信JS-SDK初始化成功');
                wx.chooseWXPay({
                    appId: e.app_id, 
                    timestamp: e.timestamp,
                    nonceStr: e.nonce_str,
                    package: e.package,
                    signType: e.sign_type,
                    paySign: e.sign,
                    success: (res) => {
                    console.log('微信支付成功', res);
                        this.$router.push({path:'/successPage',query:{backUrl: '/orderList?orderStatus=2', order_id: x}})
                    },
                    fail: (err) => {
                    console.error('微信支付失败', err);
                    // TODO: 处理支付失败的逻辑
                    }
                });
            });

        },
    },
}
</script>
<style>
    .prod-detail-page {
        width: 100vw;
        background: #f9f9f9;
        padding-bottom: 36px;
    }
    .prodPic {
        width: 100%;
        display: block;
    }
    .prod-name {
        font-size: 18px;
        line-height: 24px;
        text-indent: 8px;
        color: #000;
        padding: 8px 0;
        background: #fff;
    }
    .prod-price-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        background: #fff;
        padding-bottom: 12px;
    }
    .prodAmt {
        display: flex;
        align-items: flex-end;
        padding-left: 8px;
    }
    .prodAmt > b {
        font-size: 10px;
        color: #EC899C;
        font-weight: 700;
    }
    .prodAmt > span {
        font-size: 14px;
        color: #EC899C;
        font-weight: 700;
        margin-right: 4px;
    }
    .prodAmt > label {
        font-size: 12px;
        color: #999;
        text-decoration: line-through;
    }
    .prod-left-count {
        font-size: 12px;
        color: #999;
        margin-right: 8px;
    }

    .prod-more-info {
        width: 100vw;
        background: #fff;
    }
    .info-chapter {
        padding: 8px;
        font-size: 14px;
        color: #000;
    }
    .prod-evaluate {
        margin-bottom: 20px;
    }
    
    .prod-more-info-title {
        display: flex;
        justify-content: center;
        background: #f9f9f9;
    }
    .prod-more-info-title > div {
        color: #000;
        font-size: 18px;
        margin-bottom: 8px;
    }
    .prod-bottom-btn {
        height: 36px;
        width: 90vw;
        background: #13A1EE;
        color: #fff;
        border-radius: 24px;
        line-height: 36px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        position: fixed;
        bottom: 16px;
        left: 5vw;
        box-shadow: 0 4px 6px 0 rgba(211,211,211,0.50);
    }
</style>
