export default function setRemInit(baseWidth = 750) {
    // baseSize 是用来设置html font-size的大小 。
    function setRem(){
      const dpr = window.devicePixelRatio;//当前显示设备的物理像素分辨率与CSS像素分辨率之比。
      const currentWidth = document.documentElement.clientWidth;
      let remSize = 0;
      let scale = 0;
      let baseSize = 18.75; // 1rem = 20px
      scale = currentWidth / baseWidth; // 当前页面宽度相对于 750 px(设计稿尺寸)的缩放比例，可根据自己需要修改。
      remSize = baseWidth / baseSize; 
      remSize = remSize * scale; 
      document.documentElement.style.fontSize = remSize + 'px'; // 设置页面根节点字体大小
      document.documentElement.setAttribute('data-dpr', `${dpr}`);
    }
      // 初始化
      setRem();
      // 改变窗口大小时重新设置 rem
      window.addEventListener('resize', setRem);
    }