<template>
    <div class="search-page" @scroll="handleScroll">
        <div class="search-fixed-top">
            <div class="search-list-tabs">
                <van-tabs v-model="active">
                    <van-tab title="全部"></van-tab>
                    <van-tab :title="item.name" v-for="(item) in cateList" :key="item.id"></van-tab>
                </van-tabs>
            </div>
            <div class="search-page-header">
                <div class="search-page-header-input">
                    <img class="search-icon" src="../assets/img/search.png" />
                    <input placeHolder="搜索你想要的产品" v-model="inputValue" @keyup.enter="handleSubmit" />
                </div>
                <div class="search-btn" @click="handleSubmit">搜索</div>
            </div>
        </div>
        <div class="search-page-prod-desc">精选产品</div>
        <div class="search-page-prod-list">
            <div class="search-page-prod" v-for="item in goodsList" :key="item.id">
                <prodItem :goods="item" />
            </div>
            <Empty description="暂无商品" v-if="!goodsList.length" />
        </div>
    </div>
</template>
<script>
import prodItem from '../components/prodItem.vue'
import { Empty } from 'vant';
import { types, goods } from '../api/index.js'
export default {
    name: 'prdList',
    components: { prodItem, Empty },
    data(){
        return {
            cateList: [],
            active: 0,
            typeId: 0,
            goodsList: [],
            pageNum: 1,
            moreGoods: false,
            inputValue: ''
        }
    },
    mounted(){
        this.getCate();
        // this.getGoods(this.$route.query.cateId);
    },
    watch: {
        active(newV){
            console.log('newV========>', newV)
            this.selectCate(newV)
        }
    },
    methods: {
        getCate(){
            types({type: 0}).then(res => {
                if(res.message == 'success') {
                    this.cateList = res.data
                    setTimeout(() => {
                        this.setActive(this.$route.query.cateId);
                    }, 100)
                }
            })
        },
        setActive(e){
            if(!e) {
                this.getGoods(0)
                return
            }
            for(var i = 0;i < this.cateList.length;i++) {
                if(this.cateList[i].id == e) {
                    this.active = i + 1
                    console.log('this.active', this.cateList[i].id, e, i)
                    return
                }
            }
        },
        selectCate(e){
            this.inputValue = ''
            console.log('selectCate', e)
            if(e == 0) {
                this.typeId = 0
            }else {
                this.typeId = this.cateList[e - 1].id
            }
            this.pageNum = 1;
            this.getGoods(this.typeId)
        },
        getGoods(e){
            let goodsData = {
                page: this.pageNum,
                page_size: 10,
                home_show_type: 0,
                sale_type: 3,
                type_id: e,
                goods_name: this.inputValue
            }
            goods(goodsData).then(res => {
                this.moreGoods = false
                if(res.message == 'success') {
                    if(this.pageNum == 1) {
                        this.goodsList = res.data.data || []
                    }else {
                        this.goodsList = this.goodsList.concat(res.data.data)
                    }
                    this.moreGoods = this.goodsList.length < res.data.total
                    console.log('goodsList======>',this.goodsList)
                }
            })
        },
        handleScroll(e){
            if((e.target.scrollHeight - e.target.scrollTop < e.target.offsetHeight + 20) && this.moreGoods) {
                this.pageNum = this.pageNum + 1
                this.getGoods(this.typeId)
            }
        },
        handleSubmit(){
            this.getGoods(this.typeId)
        }
    },
}
</script>
<style>
    .search-page {
        padding: 100px 8px 80px;
        box-sizing: border-box;
        height: 100vh;
        box-sizing: border-box;
        overflow: auto;
    }
    .search-fixed-top {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100vw;
    }
    .search-list-tabs {
        width: 100vw;
        height: 44px;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .search-page-header {
        width: 100%;
        padding: 8px 0;
        box-sizing: border-box;
        background: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .search-page-header-input {
        width: 308px;
        height: 28px;
        background: #fff;
        border-radius: 14px;
        display: flex;
        align-items: center;
        margin-right: 12px;
    }
    .search-icon {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        margin-left: 8px;
    }
    .search-page-header-input > input {
        height: 24px;
        width: 260px;
        border: none;
        outline: none;
        font-size: 14px;
    }
    .search-btn {
        font-size: 14px;
        color: #999;
        white-space: nowrap;
    }
    .search-page-prod-desc {
        font-size: 16px;
        padding: 0 8px 8px;
        font-weight: 700;
        color: #000;
    }
    .search-page-prod-list {
        background: #fff;
        border-radius: 8px;
        padding: 8px 8px 0;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .search-page-prod-list .van-empty {
        width: 100%;
    }
    .search-page-prod {
        width: calc(50% - 8px);
        margin-bottom: 12px;
        background: rgba(255,255,255,0.90);
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 4px 6px 0 rgba(211,211,211,0.50);
    }
</style>

