import request from '../utils/request'

let root = '/server_zc'

// export function getToken(data) {
//     let url = 'https://api.weixin.qq.com/sns/oauth2/access_token?'
//     for(let key in data) {
//         url = url + key + '=' + data[key] + '&'
//     }
//     url = url.slice(0, -1)
//     return request({
//         url: url,
//         method: 'get',
//         data
//     })
// }

export function login(data) {
    return request({
        url: root + '/user/login',
        method: 'POST',
        data
    })
}
export function userInfo(data) {
    return request({
        url: root + '/user/detail',
        method: 'POST',
        data
    })
}
export function types(data) {
    return request({
        url: root + '/types',
        method: 'POST',
        data
    })
}
export function goods(data) {
    return request({
        url: root + '/goods',
        method: 'POST',
        data
    })
}
export function goodsDetail(data) {
    return request({
        url: root + '/goods/detail',
        method: 'POST',
        data
    })
}
export function comments(data) {
    return request({
        url: root + '/comments',
        method: 'POST',
        data
    })
}
export function orders(data) {
    return request({
        url: root + '/orders',
        method: 'POST',
        data
    })
}
export function createOrders(data) {
    return request({
        url: root + '/order/create',
        method: 'POST',
        data
    })
}
export function ordersDetail(data) {
    return request({
        url: root + '/order/detail',
        method: 'POST',
        data
    })
}
export function createPay(data) {
    return request({
        url: root + '/pay/create',
        method: 'POST',
        data
    })
}
export function paySignInfo(data) {
    return request({
        url: root + '/pay/sign_info',
        method: 'POST',
        data
    })
}
export function wxSignInfo(data) {
    return request({
        url: root + '/weixin/sign',
        method: 'POST',
        data
    })
}
export function checkPayStatus(data) {
    return request({
        url: root + '/pay/check_status',
        method: 'POST',
        data
    })
}
export function refundList(data) {
    return request({
        url: root + '/refund_orders',
        method: 'POST',
        data
    })
}
export function creaRefund(data) {
    return request({
        url: root + '/refund_order/create',
        method: 'POST',
        data
    })
}
export function cancelRefund(data) {
    return request({
        url: root + '/refund_order/cancel',
        method: 'POST',
        data
    })
}
export function refundDetail(data) {
    return request({
        url: root + '/refund_order/detail',
        method: 'POST',
        data
    })
}

export function expandOrder(data) {
    return request({
        url: root + '/order/expand/create',
        method: 'POST',
        data
    })
}
export function expandDetail(data) {
    return request({
        url: root + '/order/expand/detail',
        method: 'POST',
        data
    })
}
export function createComment(data) {
    return request({
        url: root + '/comment/create',
        method: 'POST',
        data
    })
}
export function loginOut(data) {
    return request({
        url: root + '/user/logout',
        method: 'POST',
        data
    })
}