<template>
    <div class="index-warp">
        <tabBar />
    </div>
</template>
<script>
import tabBar from '../components/nav'
export default {
    name:'IndexPage',
    components:{ tabBar },
    methods: {
    },
}
</script>
<style lang="scss">
    html {
        background: #f9f9f9;
    }
    .index-warp {
        width: 100vw;
        min-height: 100vh;
    }
</style>



