<template>
  <div id="app">
    <keep-alive>
        <router-view></router-view>
    </keep-alive>
    <backBtn />
  </div>
</template>

<script>
import backBtn from './components/backBtn'
// import { login } from './api/index.js'
export default {
  name: 'App',
  components: {
    backBtn
  },
  beforeCreate() {
    // let code = this.$route.query.code
    // console.log('code=======>', code)
    // if(code && !window.localStorage.getItem('token')) {
    //     this.getLoginInfo(code)
    // }
  },
  created() {
  },
  methods: {
    // getLoginInfo(e){
    //     login({code: e, login_type: 1}).then(res => {
    //         if(res.message == 'success') {
    //             window.localStorage.setItem('token',res.data)
    //         }
    //     })
    // }
  },
}
</script>

<style>
html,body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
