import VueRouter from 'vue-router'
import Vue from 'vue'

import LoginPage from '../views/login'
import IndexPage from '../views/indexPage'
import HomePage from '../views/homePage'
import PrdListPage from '../views/prdListPage'
import UserCenter from '../views/userCenter'
import PrdDetailPage from '../views/prdDetailPage'
import OrderList from '../views/orderList'
import OrderDetail from '../views/orderDetail'
import RefundDetail from '../views/refundDetail'
import CommentList from '../views/commentList'
import SuccessPage from '../views/successPage'
import LoginOutPage from '../views/loginOut'

Vue.use(VueRouter)
const routes = [{
    path:'/login',
    component:LoginPage
},{
    path:'/indexPage',
    component:IndexPage,
    children:[
        {
            path:'/homePage',
            component:HomePage
        },{
            path:'/prdListPage',
            component:PrdListPage
        },{
            path:'/userCenter',
            component:UserCenter
        }
    ]
},{
    path:'/prdDetailPage',
    component:PrdDetailPage
},{
    path:'/orderList',
    component:OrderList
},{
    path:'/orderDetail',
    component:OrderDetail
},{
    path:'/refundDetail',
    component:RefundDetail
},{
    path:'/commentList',
    component:CommentList
},{
    path:'/successPage',
    component:SuccessPage
},{
    path:'/loginOut',
    component:LoginOutPage
},];
const router = new VueRouter({
    mode: 'history',
    routes
})
export default router