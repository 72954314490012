<template>
    <div class="order-detail-page">
        <div class="order-status">
            <van-icon class="order-status-icon" color="#FCA33E" name="diamond-o" />
            <div class="order-status-info">
                <div>{{params.status == 1?'已创建':params.status == 2?'商家同意':params.status == 3?'商家拒绝':params.status == 4?'已退款':'已取消'}}</div>
                <label v-if="params.status == 1">退款单已经创建，请等待商家审核退款。</label>
                <label v-else-if="params.status == 2">商家已经审核统一退款，稍后请确认退款到账。</label>
                <label v-else-if="params.status == 3">商家拒绝了您的退款，拒绝理由：{{ params.refused_reason || '商家未给出退款理由，如有疑问请联系客服。' }}。</label>
                <label v-else-if="params.status == 4">退款已经完成，希望您下次能在本平台找到心仪的商品</label>
                <label v-else-if="params.status == 5">退款已取消，如果要再次发起，请在订单中寻找，请重新发起。</label>
                <label v-else-if="params.status == 6">退款进行中，请耐心等待退款完成。</label>
                <label v-else>退款异常，请联系客服处理。</label>
            </div>
        </div>
        <div class="order-goods">
            <div class="order-msg">
                <div class="order-pic">
                    <img :src="params.order_goods.cover_image" />
                </div>
                <div class="order-info">
                    <div class="order-name">{{params.order_goods.goods_name}}</div>
                    <div class="order-desc">{{params.order_goods.desc}}</div>
                    <div class="order-old-price"><span>¥</span>{{params.amount/100}}</div>
                </div>
            </div>
            <div class="order-operate">
                <div class="order-operate-item red" v-if="params.status == 2 || params.status == 3" @click="refund">退款</div>
                <div class="order-operate-item" v-if="params.status ==3 && params.order_goods.is_comment == 1" @click="comment">评论</div>
            </div>
            <div class="order-amt" v-if="params.status == 2 || params.status == 3">
                <span>实付款</span>
                <div><span>¥</span>{{params.payment_amount/100}}</div>
            </div>
            <div class="cut-line"></div>
            <div class="order-other-info">
                <div class="order-other-info-item">
                    <div>退款单编号:</div>
                    <div>{{params.refund_order_id}}</div>
                </div>
                <div class="order-other-info-item">
                    <div>订单编号:</div>
                    <div>{{params.order_id}}</div>
                </div>
                <div class="order-other-info-item">
                    <div>用户ID:</div>
                    <div>{{params.user_id}}</div>
                </div>
                <div class="order-other-info-item">
                    <div>退款单创建时间:</div>
                    <div>{{params.create_time}}</div>
                </div>
                <div class="order-other-info-item" v-if="params.status == 4">
                    <div>退款时间:</div>
                    <div>{{params.refund_time}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import wx from 'weixin-js-sdk'
import { refundDetail } from '../api/index.js'
import { timestampToDateTime } from '@/utils/auth'
export default {
    name: 'orderDetail',
    data() {
        return {
            params: {}
        }
    },
    activated() {
        this.getRefundDetail(this.$route.query.id)
    },
    methods: {
        comment(){
        },
        refund(){
        },
        getRefundDetail(e){
            refundDetail({refund_order_id: e}).then(res => {
                console.log('getOrderDetail========>', res)
                if(res.message == 'success') {
                    this.params = res.data
                    this.params.create_time = timestampToDateTime(this.params.create_time_unix)
                    this.params.refund_time = timestampToDateTime(this.params.refund_time_unix)
                }
            })
        }
    },
}
</script>
<style>
.order-detail-page {
    width: 100vw;
    background: #f9f9f9;
    padding: 12px 8px;
    box-sizing: border-box;
}
.order-status {
    background: #fff;
    padding: 12px;
    display: flex;
    font-size: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    margin-bottom: 12px;
}
.order-status-info {
    width: 100px;
    flex-grow: 1;
    margin-left: 8px;
}
.order-status-info > div {
    color: #FCA33E;
    font-size:16px;
    font-weight: 700;
}
.order-status-info > label {
    color: #999;
    font-size: 12px;
    line-height: 16px;
    display: block;
}
.order-status-icon {
    margin-top: 2px;
}
.order-goods {
    background: #fff;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 8px;
}
.order-operate {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
}
.order-amt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}
.order-amt > span {
    font-size: 14px;
    color: #999;
}
.order-amt > div {
    font-size: 14px;
    color: #E14A3F;
    font-weight: 700;
}
.order-amt > div > span {
    font-size: 12px;
}
.cut-line {
    height: 1px;
    width: 100%;
    background: #ccc;
    margin-bottom: 12px;
}
.order-other-info-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}
.order-other-info-item > div {
    font-size: 14px;
    color: #999;
}
</style>
