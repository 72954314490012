<template>
    <div class="prod-evaluate-item">
        <div class="evaluate-ava">
            <img v-if="item.user.head_image" :src="item.user.head_image" />
            <img v-else src="../assets/img/ava.jpg" />
        </div>
        <div class="evaluate-msg">
            <div class="evaluate-name">
                <div>{{ item.user.nickname }}</div>
                <div>{{ createTime }}</div>
            </div>
            <div class="evaluate-info">{{ item.content }}</div>
        </div>
    </div>
</template>
<script>
import { timestampToDateTime } from '@/utils/auth'
export default {
    name: 'commentItem',
    props: {
        item: {
            type: Object,
        },
    },
    data() {
        return {
            createTime: ''
        }
    },
    mounted() {
        this.createTime = timestampToDateTime(this.props.item.create_time_unix)
    },
}
</script>
<style>
.prod-evaluate-item {
    width: 100%;
    display: flex;
    background: #fff;
    padding: 8px;
    box-sizing: border-box;
}
.evaluate-ava {
    width: 36px;
    height: 36px;
    margin-right: 8px;
}
.evaluate-ava > img {
    width: 36px;
    height: 36px;
}
.evaluate-msg {
    width: calc( 100% - 44px );
    padding: 8px;
    box-sizing: border-box;
}
.evaluate-name {
    color: #9195a3;
    font-size: 12px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}
.evaluate-info {
    color:  #000;
    font-size: 14px;
}
</style>
